.stars {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  transform: rotate(-45deg);
}
.razorpay-backdrop{
  background: #f5f5f5 !important;
}
.star {
  --star-color: var(--primary-color);
  --star-tail-length: 6em;
  --star-tail-height: 2px;
  --star-width: calc(var(--star-tail-length) / 6);
  --fall-duration: 9s;
  --tail-fade-duration: var(--fall-duration);
  position: absolute;
  top: var(--top-offset);
  left: 0;
  width: var(--star-tail-length);
  height: var(--star-tail-height);
  color: var(--star-color);
  background: linear-gradient(45deg, currentColor, transparent);
  border-radius: 50%;
  filter: drop-shadow(0 0 6px currentColor);
  transform: translate3d(104em, 0, 0);
  animation: fall var(--fall-duration) var(--fall-delay) linear infinite, tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;
}
@media screen and (max-width: 750px) {
  .star {
    animation: fall var(--fall-duration) var(--fall-delay) linear infinite;
  }
}
.star:nth-child(1) {
  --star-tail-length: 5.9em;
  --top-offset: 33.34vh;
  --fall-duration: 7.935s;
  --fall-delay: 5.113s;
}
.star:nth-child(2) {
  --star-tail-length: 5.22em;
  --top-offset: 96.62vh;
  --fall-duration: 6.394s;
  --fall-delay: 4.592s;
}
.star:nth-child(3) {
  --star-tail-length: 5.03em;
  --top-offset: 48.29vh;
  --fall-duration: 10.883s;
  --fall-delay: 6.068s;
}
.star:nth-child(4) {
  --star-tail-length: 6.74em;
  --top-offset: 54.7vh;
  --fall-duration: 6.572s;
  --fall-delay: 9.539s;
}
.star:nth-child(5) {
  --star-tail-length: 5.48em;
  --top-offset: 44.5vh;
  --fall-duration: 9.058s;
  --fall-delay: 3.293s;
}
.star:nth-child(6) {
  --star-tail-length: 6.79em;
  --top-offset: 18.43vh;
  --fall-duration: 8.971s;
  --fall-delay: 4.897s;
}
.star:nth-child(7) {
  --star-tail-length: 5.94em;
  --top-offset: 85.56vh;
  --fall-duration: 11.594s;
  --fall-delay: 5.526s;
}
.star:nth-child(8) {
  --star-tail-length: 7.3em;
  --top-offset: 47.53vh;
  --fall-duration: 9.809s;
  --fall-delay: 5.188s;
}
.star:nth-child(9) {
  --star-tail-length: 6.41em;
  --top-offset: 76.38vh;
  --fall-duration: 9.067s;
  --fall-delay: 4.334s;
}
.star:nth-child(10) {
  --star-tail-length: 5.66em;
  --top-offset: 61.58vh;
  --fall-duration: 8.912s;
  --fall-delay: 5.951s;
}
.star:nth-child(11) {
  --star-tail-length: 7.46em;
  --top-offset: 40.1vh;
  --fall-duration: 7.609s;
  --fall-delay: 1.322s;
}
.star:nth-child(12) {
  --star-tail-length: 7.06em;
  --top-offset: 30.99vh;
  --fall-duration: 7.786s;
  --fall-delay: 3.633s;
}
.star:nth-child(13) {
  --star-tail-length: 5.87em;
  --top-offset: 8.23vh;
  --fall-duration: 7.986s;
  --fall-delay: 4.892s;
}
.star:nth-child(14) {
  --star-tail-length: 6.46em;
  --top-offset: 85.72vh;
  --fall-duration: 6.039s;
  --fall-delay: 1.663s;
}
.star:nth-child(15) {
  --star-tail-length: 6.59em;
  --top-offset: 49.31vh;
  --fall-duration: 8.577s;
  --fall-delay: 7.833s;
}
.star:nth-child(16) {
  --star-tail-length: 6.99em;
  --top-offset: 99.2vh;
  --fall-duration: 10.252s;
  --fall-delay: 1.516s;
}
.star:nth-child(17) {
  --star-tail-length: 7.28em;
  --top-offset: 67.42vh;
  --fall-duration: 10.098s;
  --fall-delay: 5.289s;
}
.star:nth-child(18) {
  --star-tail-length: 7.03em;
  --top-offset: 30.82vh;
  --fall-duration: 6.583s;
  --fall-delay: 4.582s;
}
.star:nth-child(19) {
  --star-tail-length: 5.26em;
  --top-offset: 68.45vh;
  --fall-duration: 8.177s;
  --fall-delay: 0.425s;
}
.star:nth-child(20) {
  --star-tail-length: 5.74em;
  --top-offset: 41.46vh;
  --fall-duration: 6.477s;
  --fall-delay: 9.257s;
}
.star:nth-child(21) {
  --star-tail-length: 6.14em;
  --top-offset: 31.15vh;
  --fall-duration: 10.593s;
  --fall-delay: 1.272s;
}
.star:nth-child(22) {
  --star-tail-length: 5.79em;
  --top-offset: 94.88vh;
  --fall-duration: 7.345s;
  --fall-delay: 8.178s;
}
.star:nth-child(23) {
  --star-tail-length: 6.39em;
  --top-offset: 78.95vh;
  --fall-duration: 6.783s;
  --fall-delay: 4.874s;
}
.star:nth-child(24) {
  --star-tail-length: 6.66em;
  --top-offset: 43.59vh;
  --fall-duration: 6.752s;
  --fall-delay: 2.081s;
}
.star:nth-child(25) {
  --star-tail-length: 6.24em;
  --top-offset: 10.38vh;
  --fall-duration: 6.655s;
  --fall-delay: 4.247s;
}
.star:nth-child(26) {
  --star-tail-length: 6.76em;
  --top-offset: 88.15vh;
  --fall-duration: 11.558s;
  --fall-delay: 6.753s;
}
.star:nth-child(27) {
  --star-tail-length: 6.81em;
  --top-offset: 93.38vh;
  --fall-duration: 9.411s;
  --fall-delay: 6.614s;
}
.star:nth-child(28) {
  --star-tail-length: 6.92em;
  --top-offset: 87.9vh;
  --fall-duration: 10.181s;
  --fall-delay: 7.617s;
}
.star:nth-child(29) {
  --star-tail-length: 5.7em;
  --top-offset: 34.87vh;
  --fall-duration: 6.118s;
  --fall-delay: 7.603s;
}
.star:nth-child(30) {
  --star-tail-length: 6.15em;
  --top-offset: 23.72vh;
  --fall-duration: 11.372s;
  --fall-delay: 4.925s;
}
.star:nth-child(31) {
  --star-tail-length: 6.93em;
  --top-offset: 78.71vh;
  --fall-duration: 11.258s;
  --fall-delay: 6.896s;
}
.star:nth-child(32) {
  --star-tail-length: 6.65em;
  --top-offset: 72.1vh;
  --fall-duration: 8.138s;
  --fall-delay: 2.002s;
}
.star:nth-child(33) {
  --star-tail-length: 6.97em;
  --top-offset: 86.08vh;
  --fall-duration: 10.448s;
  --fall-delay: 1.557s;
}
.star:nth-child(34) {
  --star-tail-length: 5.58em;
  --top-offset: 31.99vh;
  --fall-duration: 6.148s;
  --fall-delay: 8.473s;
}
.star:nth-child(35) {
  --star-tail-length: 6.64em;
  --top-offset: 81.3vh;
  --fall-duration: 10.588s;
  --fall-delay: 4.207s;
}
.star:nth-child(36) {
  --star-tail-length: 6.37em;
  --top-offset: 66.53vh;
  --fall-duration: 9.058s;
  --fall-delay: 2.307s;
}
.star:nth-child(37) {
  --star-tail-length: 6.79em;
  --top-offset: 19.13vh;
  --fall-duration: 8.789s;
  --fall-delay: 0.388s;
}
.star:nth-child(38) {
  --star-tail-length: 6.5em;
  --top-offset: 98.39vh;
  --fall-duration: 7.435s;
  --fall-delay: 8.501s;
}
.star:nth-child(39) {
  --star-tail-length: 6.7em;
  --top-offset: 0.71vh;
  --fall-duration: 8.18s;
  --fall-delay: 5.531s;
}
.star:nth-child(40) {
  --star-tail-length: 6.97em;
  --top-offset: 65.23vh;
  --fall-duration: 8.773s;
  --fall-delay: 5.877s;
}
.star:nth-child(41) {
  --star-tail-length: 5.16em;
  --top-offset: 5.35vh;
  --fall-duration: 10.373s;
  --fall-delay: 4.596s;
}
.star:nth-child(42) {
  --star-tail-length: 6.92em;
  --top-offset: 43.35vh;
  --fall-duration: 9.502s;
  --fall-delay: 8.396s;
}
.star:nth-child(43) {
  --star-tail-length: 6.85em;
  --top-offset: 33.58vh;
  --fall-duration: 9.378s;
  --fall-delay: 6.967s;
}
.star:nth-child(44) {
  --star-tail-length: 7.39em;
  --top-offset: 3.08vh;
  --fall-duration: 6.311s;
  --fall-delay: 3.732s;
}
.star:nth-child(45) {
  --star-tail-length: 6.95em;
  --top-offset: 19.22vh;
  --fall-duration: 9.091s;
  --fall-delay: 0.07s;
}
.star:nth-child(46) {
  --star-tail-length: 6.09em;
  --top-offset: 99.74vh;
  --fall-duration: 9.47s;
  --fall-delay: 9.364s;
}
.star:nth-child(47) {
  --star-tail-length: 6.57em;
  --top-offset: 51.81vh;
  --fall-duration: 9.688s;
  --fall-delay: 3.788s;
}
.star:nth-child(48) {
  --star-tail-length: 5em;
  --top-offset: 92vh;
  --fall-duration: 9.633s;
  --fall-delay: 7.021s;
}
.star:nth-child(49) {
  --star-tail-length: 6.44em;
  --top-offset: 29.28vh;
  --fall-duration: 7.9s;
  --fall-delay: 9.354s;
}
.star:nth-child(50) {
  --star-tail-length: 6.48em;
  --top-offset: 52.52vh;
  --fall-duration: 11.809s;
  --fall-delay: 6.962s;
}
.star::before, .star::after {
  position: absolute;
  content: "";
  top: 0;
  left: calc(var(--star-width) / -2);
  width: var(--star-width);
  height: 100%;
  background: linear-gradient(45deg, transparent, currentColor, transparent);
  border-radius: inherit;
  animation: blink 2s linear infinite;
}
.star::before {
  transform: rotate(45deg);
}
.star::after {
  transform: rotate(-45deg);
}

@keyframes fall {
  to {
    transform: translate3d(-30em, 0, 0);
  }
}
@keyframes tail-fade {
  0%, 50% {
    width: var(--star-tail-length);
    opacity: 1;
  }
  70%, 80% {
    width: 0;
    opacity: 0.4;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}
@keyframes blink {
  50% {
    opacity: 0.6;
  }
}