@import 'custom.scss';


body{font-family: 'Montserrat', sans-serif !important;}
a{ text-decoration: none;}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #68013F;
  --bs-btn-border-color: #68013F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #68013F;
  --bs-btn-hover-border-color: #68013F;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #68013F;
  --bs-btn-active-border-color: #68013F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #68013F;
  --bs-btn-disabled-border-color: #68013F;
}

.btn-outline-primary {
  --bs-btn-color: #68013F;
  --bs-btn-border-color: #68013F;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #68013F;
  --bs-btn-hover-border-color: #68013F;
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #68013F;
  --bs-btn-active-border-color: #68013F;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #68013F;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #68013F;
  --bs-gradient: none;
}
.fw-800{ font-weight: 800;}
.fw-500{ font-weight: 500;}
.fw-600{ font-weight: 600;}
.text-primary{ color: #68013F !important;}
strong{ font-weight: 600;}
.navbar-brand img{ max-height: 80px;}
.nav-link{ font-weight: 500; color: #222; font-size: 16px; padding: 10px 20px !important;}
.nav-link.active{ font-weight: 600; color: #68013F !important;}
.nav-link img{ margin: -5px 5px 0 0;}
.dropdown-toggle::after { display: none; }
.dropdown:hover .dropdown-menu{ display: block;}
.dropdown-menu{ padding: 0; border-radius: 0; border: none; width: auto;}
.dropdown-menu .dropdown-item{ font-size: 14px; padding: 10px 20px;}
.dropdown-menu .dropdown-item:hover, .dropdown-item.active, .dropdown-item:active { background-color: #68013F; color: #fff;}
.navbar-toggler{ border:none; box-shadow: none;}
.navbar-toggler:focus { box-shadow: none;}
.navbar-collapse{ background-color: #F9D7CE;}
.carousel-control-prev-icon{ background: url('../public/assets/images/icon-arrow-left.svg') no-repeat center center /cover !important; opacity: 1;}
.carousel-control-next-icon{ background: url('../public/assets/images/icon-arrow-right.svg') no-repeat center center /cover !important; opacity: 1;}
.carousel-indicators [data-bs-target] { background-color: #F9D7CE;  width: 40px; height: 5px; margin: 0px 5px; border-radius: 10px;  border: none; }
.carousel-indicators .active { background-color: #68013F;}
.accordion-button::after { background: url('../public/assets/images/icon-arrow-accordion.svg') no-repeat center center !important; }
.navbar{ padding:0; background: #F9D7CE;}
.banner{ padding-bottom: 50px;}
.section{ padding: 60px 0;}
.section.features{ padding: 0;}
.section.testimonials{ padding-bottom: 0;}
.section.faq{ background-color: #F9D7CE;}
.site-features{ position: relative; padding-left: 90px; margin-bottom: 40px;}
.site-features .icon_holder{ width: 75px; height: 75px; position: absolute; left: 0; top: -10px; text-align: center; background-color: #F9D7CE; border-radius: 20px; padding: 12px; }
.site-features h3{ font-size: 20px; font-weight: 600; margin: 0 0 10px; }
.site-features p{ font-size: 13px; color: #555;}

.product-detail .prod-name{ font-size: 40px; text-transform: uppercase; font-weight: 800; margin: 0; color: #68013F;}
.product-detail .prod-var{font-size: 20px; color: #68013F; text-transform: uppercase; margin: 0;}
.product-detail .prod_features { padding: 0; margin: 30px 0; list-style: none;}
.product-detail .prod_features ul {padding: 0;}
.product-detail .prod_features li { position: relative; padding-left: 25px; font-size: 18px; margin-bottom: 15px; padding-left: 30px; list-style: none;}
.product-detail .prod_features li:before { position: absolute; content: ""; left: 0; top: 5px; width: 15px; height: 15px; background: url('../public/assets/images/icon-list-bullet.svg') no-repeat center center / cover; }
.product-detail.home{ background: #F9D7CE; padding: 80px 150px 80px 100px; position: relative; border-radius: 100px; min-height: 700px;}
.product-detail .price { margin-bottom: 30px;}
.product-detail .price h4{ color: #68013F; font-size: 20px; font-weight: 600; display: inline;}
.product-detail .price h4 strong{ font-size: 40px; margin-right: 10px;}
.product-detail .price .save{ font-weight: 600; color: #308B23; display: inline; margin-left: 15px;}
.product-detail-img {position: relative; display: block; width: 100%;  height: 100%;}
.product-detail-img .prod_hero{ position: absolute; top: 100px; left: -120px; z-index: 9; }
.product-detail-img .prod_hero_alt{max-width: 350px; position: absolute; bottom: -50px; left: -220px; z-index: 9;}
.left-img .product-detail.home{  padding: 80px 100px 80px 150px; }
.left-img .product-detail-img .prod_hero{ right: -120px; left: inherit;}
.left-img .product-detail-img .prod_hero_alt{ left: inherit; right: -150px; bottom: -50px;}
.product-detail-img .prod_hero, .product-detail-img .prod_hero_alt { display: block;}

.testimonials-container { width: 100%; padding: 60px 60px 30px; position: relative; background: linear-gradient(180deg, #F9D7CE 0%, rgba(249, 215, 206, 0) 100%); border-radius: 30px 30px 0 0; -webkit-border-radius: 30px 30px 0 0; -moz-border-radius: 30px 30px 0 0; -ms-border-radius: 30px 30px 0 0; }
.testimonial-head { position: absolute; text-transform: uppercase; font-weight: 800; font-size: 50px; color: rgba(104, 1, 63, 0.1); transform: rotate(-90deg); left: -100px; bottom: 180px;}
.testimonials-container .quote{ position: absolute; left: 150px; top: 85px;}
.testimonials-container .quote img {width: 100px !important;}
.testimonial-title{ font-weight: 800; font-size: 24px; color: #222; text-transform: uppercase; margin-bottom: 50px;}
.testi-desc{ padding: 0 0 50px 0;}
.testi-desc p{ font-size: 18px; line-height: 30px; color: #222; display: -webkit-box; -webkit-line-clamp: 10; -webkit-box-orient: vertical; overflow: hidden;}
.testi-desc h3{ margin: 30px 0; }
.testi-desc h3 span{ font-size: 14px; color: #808080; font-weight: 400; display: block;}
.testi-img{ position: relative; width: 200px; height: 200px; overflow: hidden; border-radius: 50%; border:5px solid #fff;}

footer{ padding: 60px 0 20px; background: #68013F;}
.footer-widget h3{ font-weight: 600; font-size: 18px; line-height: 24px; text-transform: uppercase; color: #FFFFFF; margin-bottom: 30px;}
.footer-widget ul{ list-style: none; margin: 0; padding: 0;}
.footer-widget ul li{ margin-bottom: 15px;}
.footer-widget ul li a, .footer-widget p, .footer-widget a{font-size: 14px; display: block; line-height: 24px; text-transform: capitalize; color: rgba(255, 255, 255, 0.9); text-decoration: none; }
.social-links li{ display: inline-block; margin-right: 15px;}
.social-links li a:hover, .footer-widget ul li a:hover{ opacity: 0.7; }
.social-links li a img{ width: 28px;}
footer .footer-widget .maillink {text-transform: lowercase !important;}
.copyright{ text-align: center; font-size: 13px; display: block; color: rgba(255, 255, 255, 0.9); text-transform: capitalize; padding-top: 30px; border-top: 2px solid rgba(255, 255, 255, 0.1); margin-top: 30px; }

.btn-lg { padding: 15px 40px; font-size: 16px; font-weight: 600; }
.btn-lg.btn-outline-primary{ border-width: 2px; }
.mr-15{ margin-right: 15px;}

.inner-banner{ background: #F9D7CE; padding:60px 0 0px;}
.inner-cont{ text-align: center; padding: 50px 0; }
.inner-cont h1{ font-weight: 800; text-transform: uppercase; color: #68013F; font-size: 50px; }
.inner-cont nav {display: inline-block;}
.breadcrumb-item a, .breadcrumb-item.active, .breadcrumb-item + .breadcrumb-item::before { color: #68013F; }
.prod_detail_slider{ padding: 0;}

.accordion-custom .accordion-item{ margin-bottom: 20px; box-shadow: 0px 0px 20px rgba(104, 1, 63, 0.2); overflow: hidden; border-radius: 15px;}
.accordion-custom .accordion-button:focus { box-shadow: none;}
.accordion-custom .accordion-header .accordion-button{ font-size: 18px; font-weight: 500; border-radius: 15px 15px 0 0; padding: 20px 40px; color: #fff; background: #68013F; height: 70px;}
.accordion-custom .accordion-body{ padding: 15px 40px; }

.header-nxt{ margin-top: 90px;}
.form-control { height:55px; padding: 10px 20px;}
.form-label{ font-weight: 500; margin-bottom: 0px; font-size: 14px; }
.form-label .req{ color: #dc3545;}
.form-text { font-size: 13px;}
.login-cont {padding: 40px; background-color: #F9D7CE; position: relative; border-radius: 30px; }
.login-cont .form-control{ border: none; box-shadow: none;}
.form-group{ position: relative;}
.eye {position: absolute; right: 15px; top: 38px; cursor: pointer; }
.eye .iconify{ font-size: 22px; color: #999; }
.forgot-row {text-align: right;}
.fwd-password { font-weight: 500; font-size: 13px; color: #222; margin-top: 0; }
.fwd-password:hover { color: #68013F;}

/* Register */
.reg-text{text-align: center; font-size: 13px; margin: 20px 0 0;}
.reg-text a:hover, .reg-text a {color: #68013F;}

/* Cart */
.main-heading3 {margin-bottom: 20px;}
.main-heading3 h3 {font-size: 20px; font-weight: 600; color: #68013F; margin: 0;}
.main-heading3.icon-right {display: flex; justify-content: space-between; align-items: center;}
.main-heading3.icon-right .clear-cart {font-size: 13px; color: #68013F; border: 1px solid #68013F; border-radius: 6px;
   padding: 5px 10px; display: block;}
   .main-heading3.icon-right .clear-cart .iconify {margin-top: -2px;}
.cart-box .cart-header {background: #68013F; color: #fff; padding: 20px; border-radius: 6px; font-size: 17px; font-weight: 500; }
.cart-box .cart-header .cart-content {font-weight: 600;}
.cart-box .cart-body .cart-row {padding: 15px; background: #f5f5f5; border-radius: 10px; margin-top: 5px;}
.cart-box .cart-body .row {align-items: center;}
.cart-box .cart-body .cart-content h4{font-size: 16px; font-weight: 600; color: #000; margin: 0 0 5px;}
.cart-box .cart-body .cart-content p{font-size: 16px; font-weight: 600; color: #000; margin: 0;}
.cart-box .cart-body .cart-content .delete-product {font-size: 30px; color: #68013F;}
.cart-box .cart-body .cart-content.product {display: flex; align-items: center;}
.cart-box .cart-body .cart-content.product .thumb {width: 80px; padding: 2px; overflow: hidden; margin-right: 15px;}
.cart-box .cart-body .cart-content.product h4{text-transform: uppercase;}
.cart-box .cart-body .cart-content.product p {font-size: 14px; color: #666;}
.cart-box .cart-body .cart-content.product .thumb img {width: 100%; height: 100%; object-fit: cover;}
.cart-box .cart-body .cart-content.total {text-align: right;}
.cart .card-body .desc{font-size: 13px;}
.mob-prodcut {display: none;}

/* Counter Quanity */
.counter-qty {
  display: flex; height: 38px; border-radius: 50px; padding: 25px 10px;
  align-items: center; justify-content: space-between;
}

.counter-qty .numbers {
  width: 25px; height: 25px; line-height: 19px; border-radius: 50%; font-size: 18px; background: #fff;  border: 1px solid #68013F; text-align: center; color: #68013F; cursor: pointer;
}
.counter-qty .totals {
  font-size: 22px; color: #68013F;
}
/* End */
.cart-footer {background: #f9d7ce; padding: 20px; border-radius: 10px; margin-top: 10px;}
.cart-footer h3 {font-size: 16px; font-weight: 600; text-align: right; color: #68013F; text-transform: uppercase; margin-bottom: 0;}
.cart-footer h3 span {margin-right: 100px;}

.card.cart {border-top: none; margin-top: 51px;}
.card.cart .card-header{background: #68013F; color: #fff; padding: 20px; border-radius: 6px; font-size: 17px; font-weight: 600;}
.card.cart .cart-body .desc {font-size: 13px; color: #333;}
.card.cart .card-body .label-price p{
 display: flex; justify-content: space-between; font-size: 14px; font-weight: 500; color: #666; border-bottom: 1px solid #ddd; margin-bottom: 10px; padding-bottom: 10px;
}
.card.cart .card-body .label-price p span {font-weight: 600; color: #000;}
.card.cart .card-body .label-price p.bold {font-weight: 600; color: #000 !important;}

/* Checkout */
.address-box {background: #f5f5f5; padding: 15px; border-radius: 10px; position: relative; min-height: 190px; margin-bottom: 20px; cursor: pointer;}
.address-box h5 {font-size: 16px; font-weight: 500; color: #68013F; margin: 0 0 15px;}
.address-box p {font-size: 13px; line-height: 18px; color: #333;}
.address-box .address-edit {position: absolute; bottom: 10px; left: 0; right: 0; padding: 0 10px; z-index: 9;}
.address-box .address-edit a {font-size: 13px; color: #68013F; margin-left: 10px; float: right;}
.address-box .address-edit .form-check {position: relative; top: -1px;}
.address-box .address-edit .form-check .form-check-label{font-size: 13px;}
/* .address-box .address-edit a.delete {color: #f92222;} */
.address-box .address-edit  .iconify {vertical-align: middle; margin-top: -3px;}
.address-box.selected, .address-box:hover {background: #F9D7CE;}
.check-icon {position: absolute; bottom: 10px; right: 10px; font-size: 28px; color: #68013F;}
.form-check-input:checked {background: #F862BC; border-color: #F862BC;}

.add-address {background: #f5f5f5; border-radius: 10px; text-align: center;}
.add-address a {min-height: 190px; display: flex; align-items: center; justify-content: center; flex-direction: column; color: #68013F;} 
.add-address p {font-size: 16px; font-weight: 500; color: #68013F; margin-bottom: 0;}
.add-address .iconify {font-size: 34px;}
.card-style1 .card-body{padding: 25px;}
.card-style1 .card-header {background: #68013F; color: #fff; padding: 20px; border-radius: 6px; font-size: 17px; font-weight: 600;}
.card-style1.pink .card-header{background: #68013F; color: #fff; border-radius: 4px;}

.form-style1 .form-group{margin-bottom: 10px;}
.modal .modal-header .modal-title {font-size: 18px; font-weight: 500;}
textarea{resize: none; height: inherit !important;}
.modal .modal-footer .btn{padding: 10px 40px; font-size: 16px;}
.modal .btn-close {box-shadow: none;}
.form-style1 .form-group .form-control {padding: 10px; font-size: 14px; height: 45px; box-shadow: none;}
.button-group {display: flex; align-items: center;}
.button-group .cancel-text {font-size: 14px; vertical-align: middle; color: #666;}
.button-group .btn {margin-right: 20px; border-radius: 8px !important;}
.coupon-box {margin-bottom: 20px;}
.coupon-box h3 {font-size: 15px; font-weight: 600; margin: 0 0 10px; color: #68013F;}
.coupon-box p {font-size: 13px; color: #444;}
.coupon-box .input-group .form-control {font-size: 14px; box-shadow: none;}
.coupon-box .input-group .input-group-text {font-size: 16px; background: #68013F; color: #fff;}
.coupon-box .input-group .iconify {font-size: 20px; margin-right: 5px;}
.coupon-box .input-group .btn{box-shadow: none; border: none; font-weight: 600; font-size: 15px;}

/* Order Success */
.orderbox {text-align: center; display: table; margin: 0 auto;}
.orderbox .iconify {font-size: 80px; color: #308B23; margin-bottom: 25px;}
.orderbox h3 {font-size: 20px; margin: 0; color: #000; margin-bottom: 15px;}
.orderbox p {font-size: 16px; margin: 0; color: #444;}

/* My Profile */
.my-orders {position: relative;}
.my-orders:before {position: absolute; top: -1px; bottom: 0; left: 0; width: 27%; content: ""; background: #F9D7CE;}
.menu-left {position: relative; z-index: 99;}
.menu-left ul {padding: 0; margin: 0; max-width: 270px;}
.menu-left ul li {list-style: none; display: block; margin-bottom: 10px; font-size: 16px;}
.menu-left ul li a {color: #68013F; padding: 15px; display: block; border-radius: 10px;}
.menu-left ul li .iconify {font-size: 20px; vertical-align: middle; min-width: 20px; margin-right: 10px; position: relative; top: -2px;}
.menu-left ul li a:hover, .menu-left ul li.active a {background: #68013F; color: #fff;}
.account-right {padding-left: 50px;}
.main-heading2 {font-size: 22px; font-weight: 600; margin: 0 0 20px; color: #68013F;}
.button-group.text-end {justify-content: end;}
.form-half {width: 50%;}
.delete-address {margin-left: 15px; font-size: 14px; font-weight: 500; color: #f92222;}
.card-style1.address-card .card-body {padding-bottom: 5px;}
.card-header-group {display: flex; align-items: center; justify-content: space-between;}
.card-header-group .form-check label {font-size: 13px; font-weight: 600;}
.card-header-group .form-check-input {box-shadow: none !important;}

/* My Order */
.order-cover .order-row {margin-bottom: 15px;}
.order-cover .order-row .card .card-header .row{display: flex; align-items: center;}
.order-cover .order-row .card .card-header .order-id{font-size: 16px; color: #68013F; font-weight: 500;}
.order-cover .order-row .card .card-header .track-link{float: right; border: 1px solid #ddd; color: #68013F; padding: 5px 15px; font-size: 14px; border-radius: 4px; background: #fff;}
.order-cover .order-row .card .card-body .row {align-items: center;}
.order-cover .order-row .card .card-body .delivery-product h5 {font-size: 18px; margin: 0 0 5px;}
.order-cover .order-row .card .card-body .delivery-product h5 a {color: #000;}
.order-cover .order-row .card .card-body .delivery-product p {font-size: 15px; margin: 0;}
.order-cover .order-row .card .card-body .delivery-content p {font-size: 14px; margin: 0 0 5px;}
.order-cover .order-row .card .card-footer {padding: 15px; background: none; border-color: #f3f3f3;}
.order-cover .order-row .card .card-footer .row{align-items: center;}
.order-cover .order-row .card .card-footer p {margin-bottom: 0; font-size: 14px;}
.order-cover .order-row .thumb {width: 80px; padding: 2px;overflow: hidden;margin-right: 15px;}

/* Order Details */
.order-details-card .detail-address h4{font-size: 18px; font-weight: 600; margin: 0 0 10px; color: #68013F;}
.order-details-card .detail-address p{font-size: 14px; margin: 0 0 5px;}
.cart-footer .detail-grand p {text-align: right; font-size: 16px; font-weight: 500; margin: 0 0 5px;}
.cart-footer .detail-grand p label {float: left; width: 260px;}
.cart-footer .detail-grand .grand-totals {font-weight: 600; border-top: 1px dashed #68013F; padding-top: 10px; margin-top: 20px;}
.payment-type {padding: 5px 10px; border-radius: 8px; background: #dcffdb; color: #26d420; display: inline-block; font-weight: 500; margin-top: 10px; font-size: 13px;}

/* Track Order */
.track-search {padding: 15px 25px; margin-bottom: 20px;}
.track-search .row{justify-content: center;}
.track-search .form-control {margin-bottom: 0; box-shadow: none;}
.track-search .btn {height: 55px;}
.track-cover {display: table; margin: 0 auto;}

/* Vertical timeline */
.timeline{
	color:#000;
	padding:20px 20px 20px 130px;
}
.timeline ul{
	list-style-type:none;
	border-left:2px solid #d1d9d9;
	padding:10px 5px;
}
.timeline ul li{
	padding:20px 20px;
	position:relative;
	transition:.5s;
}
.timeline ul li .content {margin-top: -8px;}
.timeline ul li .content p{	
	font-size:14px; margin: 0; color: #666;
}
.timeline ul li:before{
	position:absolute;
	content:'';
	width:20px;
	height:20px;
	background-color:#d1d9d9;
	border-radius:50%;
	left:-16px;
	top:28px;
	transition:.5s;
}

.left-side {
    position: absolute; top: 15px; left: -130px; color: #000; text-align: right;
}
.left-side p {font-size: 14px; color: #666; margin: 0;}
.timeline ul li .date-text {font-size: 16px !important; font-weight: 500; color: #000 !important;}
.timeline ul li.active:before {background: #26d420;}
.account-right .button-group .btn {font-size: 16px; font-weight: 600; padding: 12px 30px;}
.form-style1 .form-group .eye {top: 33px;}
.btn.btn-md {font-size: 14px; font-weight: 600; padding: 10px 25px;}
.btn-primary:hover, .btn-primary:focus {background: #8d0456; color: #fff;} 

/* Contact */
.contact-section .contact-content h5{font-size: 16px; line-height: 20px; margin: 0;}
.contact-section .contact-content h5 .iconify {font-size: 20px; color: #68013F; vertical-align: middle; margin-top: -2px;}
.contact-section .contact-content p{font-size: 18px; font-weight: 600; margin: 0 0 10px;}
.contact-section .contact-content a {color: #000;}
.map-section {padding-bottom: 65px;}
.map-section iframe {display: block;}
.lg-none {display: none;}

/* Product List */
.product-list-box {background: #F9D7CE; border-radius: 10px; padding: 35px 20px 10px; margin-bottom: 25px;}
.product-list-box .image {width: 90%; margin: 0 auto;}
.product-list-box .content {padding: 20px 0; text-align: center;}
.product-list-box .content h4 {font-size: 26px; font-weight: 600; margin: 0 0 10px; color: #68013F; text-transform: uppercase;}
.product-list-box .content h4 a {color: #68013F;}
.product-list-box .content p {font-size: 16px; font-weight: 600; margin: 0; color: #68013F;}
.product-list-box .content p .save {color: #308B23; margin-left: 0px;}
.product-list-box .content .btn-list .btn{padding: 12px 25px;}
/* Cart Empty */
.cart-empty {padding: 35px 15px; text-align: center;}
.cart-empty h5 {margin:0 0 15px; font-size: 18px; font-weight: 600;}
.cart-empty p {font-size: 14px; line-height: 18px; color: #666; margin:0 0 20px;}
.cart-empty .btn {padding: 13px 20px; font-size: 14px;}
.d-none {display: none;}
.menu-left {position: sticky; top: 100px;}
.bulk-order .bulk-row {margin-bottom: 25px;}
.bulk-order .bulk-row:last-child {margin-bottom: 0;}

.form-group .form-select {padding: 10px; font-size: 14px; height: 45px;  box-shadow: none;}

.scroll-to-top svg {font-size: 30px;}
.cart-body{position: relative;}

.section.promo {padding: 0;}
.section.promo img {max-width: 100%;}
.section.faq {padding-bottom: 0;}

/* Responsive */
@media (max-width: 767px) {
    .header-nxt{ margin-top: 50px;}
    .navbar-brand img{ height: 50px;}    
    .nav-link {font-size: 14px; padding: 15px 20px !important;}
    .carousel{ margin: 58px 0 30px;}
    .section{ padding: 30px 0;}
    .features .row{display: flex; align-items: center;}
    .site-features{ padding-left: 80px; margin-bottom: 40px;}
    .site-features h3 {font-size: 18px; margin: 0 0 5px;}
    .site-features p {font-size: 14px; margin: 0;}
    .site-features .icon_holder{width: 65px; height: 65px;}
    .site-features .icon_holder img{width: 40px; }
    .product-detail .price h4 strong {font-size: 20px;}
    .product-detail.home, .left-img .product-detail.home { padding: 50px 30px; border-radius: 50px;}
    .product-detail .prod-name { font-size: 20px;}
    .product-detail .prod-var {font-size: 16px;}
    .product-detail .prod_features {margin: 15px 0;}
    .product-detail .prod_features li { font-size: 14px; margin-bottom: 10px;}
    .product-detail .prod_features li p {margin-bottom: 5px;}
    .product-detail .price {margin-bottom: 15px;}
    .btn-lg { padding: 10px 20px; font-size: 13px; }
    .product-detail.home {min-height: inherit; padding-bottom: 25px !important; margin-bottom: 30px;}
    .product-detail-img .prod_hero, .product-detail-img .prod_hero_alt, .left-img .product-detail-img .prod_hero, .left-img .product-detail-img .prod_hero_alt{ position: inherit; left: inherit; right: inherit; top: 40px; }
    .prod_hero_alt{ display: none !important;}
    .product-detail-img {margin-top: -20px;}
    .product-detail-img .prod_hero{ width: 70%; margin: 0 auto;}
    section.product{ padding: 0;}
    .testimonials {padding-top: 0;}
    .testimonial-title {font-size: 20px;}
    .testimonial-head {top: 220px; bottom: inherit; left: -150px; }
    .testimonials-container .quote { width: 80px; left: inherit;  top: 130px;}
    .testi-img { width: 80px; height: 80px; margin: 0 auto 15px; border: 1px solid #fff;}
    .testi-desc {padding-left: 0;}
    .testi-desc p { font-size: 14px; line-height: 20px; text-align: center;}
    .testi-desc h3 {margin: 10px 0; font-size: 18px;}
    .testi-desc h3 span {font-size: 14px;}
    .testi-desc{ text-align: center;}
    .testimonials-container { padding: 30px;}
    .testimonials-container .carousel{ margin: 30px 0 0px;;}
    .footer-widget{ margin-top: 20px;}
    .footer-widget ul li { margin-bottom: 5px; font-size: 12px;}
    .footer-widget ul li a{font-size: inherit;}
    .footer-widget h3 { margin-bottom: 10px; font-size: 16px;}
    footer{ padding: 0;}
    footer .copyright {padding-top: 15px; margin-top: 13px;}
    footer .copyright p {font-size: 10px;}
    .footer-widget.social {margin-top: 5px;}
    .banner{ padding-bottom: 0px;}
    .flex-dir-rev{ display: flex; flex-direction: row-reverse; }
    .inner-banner { padding: 60px 0 0; }
    .inner-cont h1 { font-size: 22px; }
    .inner-cont .breadcrumb li {font-size: 14px;}
    .accordion-custom{margin-top: 0;}
    .accordion-custom .accordion-header .accordion-button { font-size: 14px; padding: 20px; }
    .accordion-custom .accordion-item { border-radius: 4px; }
    .accordion-custom .accordion-header .accordion-button { border-radius: 20px 20px 0 0; }
    .cart-link-mob { position: absolute; top: 20px;  display: block !important; font-size: 14px; color: #222;  right: 70px; font-weight: 500;}
    .cart-link-mob img {margin-top: -5px;}
    .testimonial-title { margin-bottom: 0px;}
    .copyright { font-size: 12px;}
    .section.faq { padding-bottom: 15px; }
    .inner-cont { padding: 10px 0; }
    .accordion-custom .accordion-body { padding: 15px 19px; font-size: 14px; }
    .prod_detail_slider{ padding-top: 0;}
    .inner-cont p { font-size: 14px;}

    /* Listing */
    .product-list-box p .save{display: block; margin-top: 5px;}

    /* Cart */
    .cart-box .cart-header {font-size: 15px; padding: 10px;}
    .cart-box .cart-body .cart-content.product {display: block;}
    .cart-box .cart-body .cart-content.product .thumb {width: 80px;}
    .cart-box .cart-body .cart-content.product h4 {font-size: 12px;}
    .cart-box .counter-qty {
      /* flex-direction: column-reverse;  */
      background: none; 
      height: inherit;
      padding: 0;
    }
    
    .cart-box .counter-qty .numbers {width: 20px; height: 20px; line-height: 16px; font-size: 14px;}
    .cart-box .cart-body .cart-content .delete-product {font-size: 18px;}
    .cart-box .card-header .cart-content.text-end {text-align: right !important;}
    .account-right {padding-left: 0;}
    .my-orders::before {display: none;}
    .form-half {width: 100%;}
    .mob-prodcut {display: block;}
    .mob-prodcut h4 {font-size: 15px; margin: 0 0 5px;} 
    .cart-box .cart-body .cart-content.product .info {display: none;}
    .cart-box .cart-body .cart-content.price {display: none;}
    .cart-box .cart-body .cart-content .counter-qty {width: 80px;}
    .cart-box .cart-body .cart-content.total{text-align: left !important; margin-top: 5px;}
    .cart-box .cart-body .col-8 {padding-left: 20px !important;}
    .cart-box .cart-header {display: none;}
    .cart-box .cart-footer h3 span {margin-right: 20px;}

    /* My Orders */
    .order-cover .order-row .card .card-header .order-id {font-size: 14px;}
    .order-cover .order-row .card .card-header .track-link {font-size: 14px; padding: 5px 10px;}
    .order-cover .order-row .card .card-body {padding-bottom: 0;}
    .order-cover .order-row .card .card-body .delivery-product h5 {font-size: 14px;}
    .order-cover .order-row .card .card-body .delivery-content {margin-top: 10px;}
    .order-cover .order-row .card .card-footer p {font-size: 13px; text-align: left !important;}

    .menu-left {margin-bottom: 15px; display: none;}
    .menu-left ul li {font-size: 13px; margin-bottom: 5px;}
    .menu-left ul li .iconify {font-size: 14px;}
    .menu-left ul li a {padding: 5px 0;}
    .main-heading2 {font-size: 18px; margin-bottom: 10px;}
    .track-search {border-radius: 4px; padding: 15px; margin-bottom: 0;}
    .track-search .button-group {justify-content: end;}
    .track-search .button-group .btn{margin:10px; width: 48%;}
    .order-details-card .detail-address.text-end {text-align: left !important; margin-top: 10px;}
    .cart-footer .detail-grand p label {width: auto;}
    .order-cover .cart-footer {padding: 0 15px 10px;}
    .timeline ul li .date-text {font-size: 13px !important;}
    .account-right .form-style1 .button-group.text-end .btn {width: 100%; margin: 0;}

    .card-style1 .card-header, .card.cart .card-header {padding: 15px; font-size: 15px;}
    .card-style1 .card-body {padding: 15px;}
    .confirm-page .address-box{margin-bottom: 20px !important;}
    .order-details-card .detail-address h4 {font-size: 16px;}
    .modal .modal-footer .btn {padding: 10px 30px;}
    .xs-none {display: none;}
    .card.cart {margin-top: 15px;}
    .card.cart .btn-primary.btn-lg {padding: 15px; font-size: 15px;}
    .card-header-group .form-check label {font-size: 11px;}
    .card-body .add-address {margin-bottom: 15px;}
    .lg-none {display: inline-block;}
    .contact-section .contact-content {margin-bottom: 30px;}
    .contact-section .contact-content p {margin-bottom: 5px;}
    .map-section {padding-bottom: 25px;}
    .billing-card, .order-product {margin-bottom: 20px !important;}
    .detail-address.float-end {margin-top: 10px; padding-top: 10px; border-top: 1px solid #ddd;}
    .product-list-box {margin-bottom: 25px; padding: 15px;}
    .product-list-box .image {width: 125px;}
    .cart-empty p br {display: none;}
    
    header {position: relative;}
    .header-cart {display: block !important; position: absolute; top: 9px; right: 70px; z-index: 9999;}
    .header-cart a {padding: 0 !important;}

    .prod_detail_slider {text-align: center; padding: 0;}
    .prod_detail_slider img {width: 100% !important; margin: 0 auto 15px;}
    .product-detail h2{font-size: 16px;}
    .accordion-custom .accordion-header .accordion-button {min-height: 40px; height: inherit; padding: 15px !important;}
    .scroll-to-top {width: 30px !important; height: 30px !important; line-height: 25px !important; bottom: 35px; right: 20px;}
    .scroll-to-top svg {font-size: 20px;}
    header .navbar .nav-link {padding: 10px 0 !important;}
    .mobm5 {margin-bottom: 0 !important;}

}

@media only screen and (max-width: 1023px) and (min-width: 767px)  {
    .product-detail-img .prod_hero{ width: 300px; }
    .product-detail .prod-name { font-size: 30px; }
    .product-detail .prod_features li { font-size: 14px;}
    .btn-lg {padding: 10px 20px; font-size: 14px;}
    .prod_hero_alt{ display: none !important;}
    .navbar{ padding:0; background: #F9D7CE;}
    .navbar-brand img{ height: 60px;}
    .product-detail.home {padding: 40px 100px 40px 40px; position: relative; border-radius: 30px; }
    .left-img .product-detail.home { padding: 40px 40px 40px 100px; }
    .testi-img {width: 150px; height: 150px;}
    .testi-desc p { font-size: 18px; line-height: 30px;}
    .testimonials-container .quote { width: 100px; left: 100px; top: 100px;}
    .footer-widget{ margin-top: 50px;}
    footer{ padding: 0;}
    .prod_detail_slider{ padding: 0;}
    .cart-link-mob { position: absolute; top: 25px;  display: block !important; font-size: 14px; color: #222;  right: 18%; font-weight: 500;}

    .menu-left ul li {font-size: 13px;}
    .menu-left ul li a {padding: 8px;}
    .menu-left ul li .iconify {margin-right: 0px;}
    .header-nxt {margin-top: 30px;}
    .account-right {padding-left: 20px;}
    .order-cover .order-row .card .card-body .delivery-product h5, .order-cover .order-row .card .card-body .delivery-product p {font-size: 14px;}

    .track-search {padding: 0; margin-bottom: 15px;}
    .account-right .button-group .btn {padding: 12px 10px; margin: 0 5px;}
    .lg-none {display: none;}
    .order-cover .cart-box .cart-footer {padding-top: 0;}
    .form-half {width: 70%;}
  }

  @media (min-width: 992px) and (max-width: 1199px) {

    .product-list-box .image {width: 170px;}
    .product-list-box .content h4 {font-size: 16px;}
    .product-list-box .content p {font-size: 14px;}    
    .product-list-box .content .btn-list .btn {padding: 10px 15px; font-size: 15px; margin-right: 5px;}
  }

  @media (min-width: 1400px) and (max-width: 1920px) { 
    
  }

  @media (min-width: 1920px) and (max-width: 2560px)  {

    .my-orders:before {width: 33%;}

  }

  .svg-icon path {
    stroke-dasharray: 0;
    transition: stroke-dasharray 2s linear;
 }

 
 /* Toast */
 .toast {
  position: fixed; bottom: 20px; right: 20px; z-index: 99;
 }
 .toast strong {font-weight: 400; font-size: 13px;}

 .toast-success .toast-header {
  background: #308B23; color: #fff;
}
.toast .btn-close{color: #fff !important;}
.toast-danger .toast-header {
  background: #f90c0c; color: #fff;
}

.cart-empty {background: #ffe1f3; padding: 50px 20px; border-radius: 10px; min-height: 300px; text-align: center; margin: 20px 0;}
.cart-empty .icon {color: #68013F;}
.cart-empty .icon svg {font-size: 130px; }
.cart-empty h3 {font-size: 24px; color: #000; margin: 0 0 20px;}
.cart-empty p {font-size: 14px; line-height: 18px; color: #666;}

.otp-page .form-group .form-control{letter-spacing: 25px; font-size: 18px; font-weight: 600;}

.success-icon.text-danger .iconify {color: #dc3545;}

/* Cart Loader */

.cart-loader {position: relative; height: 400px; background: #eaeaea; margin-bottom: 30px;}

.cart-loader .loader-icon {position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); z-index: 9;}

.loader-icon {

  color: #8d0456;

  width: 3px;

  aspect-ratio: 1;

  border-radius: 50%;

  box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;

  transform: translateX(-38px);

  animation: loaderdot 0.5s infinite alternate linear;

  -webkit-box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;

  -webkit-transform: translateX(-38px);

  -webkit-animation: loaderdot 0.5s infinite alternate linear;

}




@keyframes loaderdot {

  50% {

    box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;

  }

  100% {

    box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;

  }

}


@-webkit-keyframes loaderdot {

  50% {

    box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;

  }

  100% {

    box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;

  }

}

/* End */

.card-scroll {
  max-height: 280px; overflow-y: auto;
}

.modal-alert-option .modal-dialog .modal-body{padding: 20px 30px !important;}

.contact-forms .enquirybox {

  background: #e8e8e8; padding: 25px; border-radius: 20px; margin: 30px 0 40px;

}

.contact-forms .enquirybox textarea{height: 83px !important;}

/* new-style */
.text-bold{
  font-size: 14px;
  margin-top: 8px;
}

.coupon-clear {
  font-size: 18px; color: #68013F; position: absolute; top: 15px; right: 100px; z-index: 9;
}

.loader-wrapper2 {
  width: 100%; height: 100%; position: fixed; top: 0; left: 0; background: rgba(255,255,255,1); z-index: 9999;
}
.loader2 {
  position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); 
}
.loader2 img {width: 100px;}

.guest-user .coupon-box .btn {
  margin-top: 23px !important; height: 45px;
}
.banner-loader {width: 100%; height: 385px;}
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.features-skeleton .thumb{width: 100%; height: 75px;}
.features-skeleton .content .headingskl {width: 80% !important; margin-bottom: 12px !important;}

.product-skeleton .headingskl{height: 30px; width: 70%; margin-bottom: 20px;}
.product-skeleton .descskl {margin-bottom: 15px; height: 30px;}
.product-skeleton .descskl.w100{width: 100%;}
.product-skeleton .descskl.w60{width: 90%;}
.product-skeleton .descskl.w60{width: 80%;}
.product-skeleton .descskl.w70{width: 70%;}
.product-skeleton .descskl.w60{width: 60%;}
.product-skeleton .descskl.w50{width: 50%;}
.product-skeleton .descskl.w40{width: 40%;}
.product-image-skl {height: 500px;}
.product-skeleton .thumb-col3 {height: 200px;}
.product-skeleton .thumb-col3.w40 {width: 40%;}
.product-skeleton .thumb-col3.w50 {width: 50%;}
.product-skeleton .thumb-col3.w60 {width: 60%;}
.product-skeleton .thumb-col4 {height: 80px;}
.product-skeleton .imageskl {width: 100%; margin: 0 auto; height: 250px; margin-bottom: 20px;}
.product-skeleton .buttonskl {height: 50px;}
.product-skeleton.center {margin-bottom: 25px;}
.product-skeleton.center .headingskl, .product-skeleton.center .descskl{margin: 0 auto 15px;}


@keyframes skeleton-loading {
  0% {
    background:hsl(12, 42%, 95%);
  }
  100% {
    background:hsl(13, 78%, 89%);
  }
}
/* End */


.header-cart {display: none;}

/* Medium Laptop */
@media (min-width: 1280px) and (max-width: 1439px)  {  
  .testimonials .offsettesti {
    margin-left: 7% !important; width: 100% !important; 
  }
  .testimonials .offsettesti .col-lg-3 {
    width: 20% !important;
  }
  .testimonials .offsettesti .col-lg-7 {
    width: 70% !important;
  }
  .testimonials-container {padding: 25px;}
  .testimonials-container .testimonial-head{font-size: 40px;}
  .testimonials .offsettesti .testi-img {width: 150px; height: 150px;}
  .testimonials .offsettesti .testi-img {margin-top: 55px;}
  .testimonials .offsettesti .testi-desc {padding-left: 0 !important;}
  .testimonials .offsettesti .testi-desc p {display: -webkit-box; -webkit-line-clamp: 8; -webkit-box-orient: vertical; overflow: hidden; font-size: 16px; line-height: 28px;}
  .testimonials-container .quote {width: 70px; margin-top: 20px; top: 35px !important;}
}

.otp-page .form-group .form-control {
    letter-spacing: 2px;
    font-size: 14px;
    font-weight: normal;
}

.captchabox img {width: 200px;}
.resend-otp .iconify {font-size: 16px; vertical-align: middle;}

/* Laoder */
.loader-wrapper {position: absolute; top: 0; bottom: 0; left: 0; right: 0; z-index: 9; overflow: hidden; z-index: 99999; background: #fff; height: 100vh;}
.loader-container {
  top: 0; left: 0; background: rgba(255,255,255,1); 
}
.loader {
  position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); 
}
.loader img {width: 100px;}